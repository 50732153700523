import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "javascript-10yearschallenge"
    }}>{`JavaScript #10YearsChallenge`}</h1>
    <h5 {...{
      "id": "april-8-2019--aptitude-softwares-office"
    }}>{`April 8, 2019 | `}<a parentName="h5" {...{
        "href": "http://twitter.com/AptitudeSW"
      }}>{`Aptitude Software's`}</a>{` office`}</h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Slides`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://github.com/kajetansw/javascript-presentation-with-spectacle"
        }}>{`https://github.com/kajetansw/javascript-presentation-with-spectacle`}</a></li>
      <li parentName="ul">{`⌚ `}<strong parentName="li">{`Duration`}</strong>{`: ~60 minutes`}</li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: presentation`}</li>
    </ul>
    <hr></hr>
    <p>{`My first professional talk (ever!) done as a part of Community of Practice (CoP) meetups at my company.`}</p>
    <p>{`For the past two years we are progressing towards migrating our products to the newest Angular framework. As I was a part of the team that did the migration first, I wanted to share my insight with other teams. I gave a talk on what exactly is JavaScript and how cool it is that we are starting adopting it!  `}</p>
    <hr></hr>
    <h3 {...{
      "id": "outline"
    }}>{`Outline`}</h3>
    <ul>
      <li parentName="ul">{`What JS is as a language`}</li>
      <li parentName="ul">{`Is JS object-oriented or functional or something else?`}</li>
      <li parentName="ul">{`JS before 2009`}</li>
      <li parentName="ul">{`Release of NodeJS and npm`}</li>
      <li parentName="ul">{`How JS is used now`}</li>
      <li parentName="ul">{`Why should we use JS`}</li>
    </ul>
    <hr></hr>
    <h3 {...{
      "id": "comments"
    }}>{`Comments`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://trends.google.com/trends/explore?date=today%205-y&q=%2Fm%2F0s8wr70,%2Fg%2F11c6w0ddw9,%2Fm%2F012l1vxv,%2Fg%2F11c0vmgx5d"
        }}>{`Is Ember dead? 😉`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/gothinkster/realworld"
        }}>{`Real world apps (one app implemented in various technologies)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      